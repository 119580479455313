<template>
  <div>
    <b-overlay
      :show="ShowLog"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="loader" />
        <!-- <div class="text-center">
          <p
            id="cancel-label"
            class="neonPink"
          >
            <img
              src="/logo/logo.png"
              alt="logo"
              width="100px"
            ><br>
            กรุณารอสักครู่...
          </p>
        </div> -->
      </template>
      <!-- <this-header /> -->
      <!-- <div v-else> -->
      <div
        class="container-new px-1"
        style="padding-bottom: 200px"
      >
        <div class="d-flex align-items-center">
          <router-link
            v-if="UserData.usertype == 'member'"
            :to="{ name: 'tanghuay-home' }"
          >
            <button class="bnt-home">
              <i class="fas fa-home" />
            </button>
          </router-link>
        </div>
        <div class="mb-2 mt-1">
          <b-col
            v-for="group_huay in LottoHeadList"
            :key="group_huay.group_id"
            md="12"
            class="p-0"
          >
            <div>
              <div>
                <div class="d-flex align-items-center mt-1">
                  <img
                    src="/icons/wheel.png"
                    alt="topic"
                    width="30"
                    class="mr-25"
                  >
                  <div class="text-black">
                    <h4 class="m-0">
                      {{ group_huay.group_name }}
                    </h4>
                  </div>
                  <!-- <hr class="hr-shadow"> -->
                </div>
              </div>
              <div class="row gutters-10 mx-auto mt-1n">
                <div
                  v-for="list_huay in group_huay.Lotto"
                  :key="list_huay.LottoHead"
                  class="col-6 p-50"
                >
                  <!-- <div
                  class="promoBox success-box block  pointer mb-2"
                  style="height: 120px;"
                  data-href="/member/lottery/government"
                  @click="buyLotto(list_huay)"
                > -->

                  <div
                    class="box-lot cursor-pointer"
                    :class="[list_huay.CloseDateName === '0 วัน 00 : 00 : 00' ? 'grascale' : '']"
                    @click="buyLotto(list_huay)"
                  >
                    <div class="time-lot">
                      <i class="far fa-clock" />
                      {{
                        formatCloseDateName(
                          list_huay.CloseDateName !== '0 วัน 00 : 00 : 00'
                            ? list_huay.CloseDateName + ' ชม.'
                            : "ปิดรับแทง"
                        )
                      }}

                    </div>
                    <div class="flag">
                      <img
                        :src="`/flag/${list_huay.short_name}.jpg`"
                        alt=""
                      >
                    </div>
                    <div class="rouded-lot">
                      <p class="m-0 p1">
                        {{ list_huay.LottoName }}
                      </p>
                      <p class="m-0 p2">
                        ปิดรับ: {{ list_huay.CloseDate }} น.
                      </p>

                      <div class="ico-next">
                        <i class="far fa-arrow-right" />
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    class="promoBox success-box pointer"
                    data-href="/member/lottery/government"
                    @click="buyLotto(list_huay)"
                  >
                    <h4
                      class="text-white ml-1"
                      style="margin-top: 5px;"
                    >
                      <img
                        v-if="list_huay.LottoPicture"
                        :src="`https://api.hapi-lot.com/api/get/img?pathfile=${EncodeBase64(
                          list_huay.LottoPicture
                        )}`"
                        width="30px"
                        class="img-lotto"
                      >
                      {{ list_huay.LottoName }}
                    </h4>
                    <p class="text-center mb-1 ml-2">
                      <i
                        key=""
                        class="far fa-stopwatch neonBlue"
                        style="margin-right: 1px; font-size: 20px"
                      />
                      <strong
                        class="text-time2 text-pink-1 countdown"
                        data-finaldate="1663686600000"
                      >
                        &nbsp;{{
                          list_huay.CloseDateName
                            ? list_huay.CloseDateName
                            : "ปิดรับแทง"
                        }}
                        ชม.</strong>
                      <br>
                    </p>
                    <small
                      style="
                        position: absolute;
                        right: 5px;
                        bottom: 1px;
                        color: #f0f0f0;
                      "
                      class="text-right"
                    >ปิดรับ : {{ list_huay.CloseDate }} น.</small>
                  </div> -->
                </div>
              </div>
            </div>
          </b-col>
        </div>

        <b-modal
          ref="modal-price"
          hide-footer
          hide-header
          centered
          size="sm"
          title="Using Component Methods"
        >
          <div class="content-box">
            <h4>ปิดรับแทง !!</h4>
            <p>ขออภัย {{ massege.LottoName }} ปิดรับแทงแล้วจ้า</p>
          </div>

          <div class="text-center mt-2">
            <button
              class="btn-confirm"
              @click="$refs['modal-price'].hide()"
            >
              ตกลง
            </button>
          </div>
        </b-modal>
        <!-- <this-footer /> -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  // BRow,
  BOverlay,
  BCol,
  //   BCard,
  VBToggle,
} from 'bootstrap-vue'
// import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ThisHeader from './component/ThisHeader.vue'
// import ThisFooter from './component/ThisFooter.vue'
export default {
  components: {
    // BCard,
    // BRow,
    BOverlay,
    BCol,
    // ThisHeader,
    // ThisFooter,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      massege: '',
      isActive: false,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      required,
      between,
      ShowLog: false,
      digits,
      show: false,
      LottoHeadList: [],
      Interval: null,
      items: [],
      userbalance: 0,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  created() {
    this.Interval = setInterval(() => {
      // this.ShowWallet()
      this.Getlottolist()
    }, 30000)
  },
  async mounted() {
    await this.Getlottolist()
    this.ShowWallet()
    this.IntervalTime = setInterval(() => {
      this.LoopCloseTime()
    }, 1000)

    // console.log(this.group_huay)
  },
  methods: {
    formatCloseDateName(val) {
      if (!val) {
        return 'ปิดรับแทง'
      }
      if (val.startsWith('0 วัน')) {
        return val.replace('0 วัน', '').trim()
      }
      return val
    },
    EncodeBase64(val) {
      const encodedText = btoa(val)
      return encodedText
    },
    Getlottolist() {
      this.$http
        .post('/member/LottoList/RoundListOpen_v2')
        .then(response => {
          this.LottoHeadList = response.data.ListLotto

          // console.log(this.LottoHeadList)
          this.LoopCloseTime()
          // this.LottoSubList = response.data.SubHead
        })
        .catch(error => console.log(error))
    },
    LoopCloseTime() {
      // eslint-disable-next-line
      for (const index_group in this.LottoHeadList) {
        const group = this.LottoHeadList[index_group]
        // eslint-disable-next-line
        for (const index in group.Lotto) {
          const Item = group.Lotto[index]
          Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
          // console.log(Item.CloseDateName)
        }
      }
    },
    // eslint-disable-next-line consistent-return
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)

      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      clearInterval(this.Interval)
      this.$router.push({ name: 'lottery' })
    },
    buyLotto(data) {
      this.massege = data
      if (
        momenttz(data.CloseDate).tz('Asia/Bangkok').valueOf()
        > momenttz().tz('Asia/Bangkok').valueOf()
      ) {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.push({
          path: `/tanghuay-online/${data.LottoName}/${data.LottoHead}/${
            data.LottoSubHead ? data.LottoSubHead : 0
          }/${data.CloseDate}/${data._id}`,
        })
      } else {
        this.$refs['modal-price'].show()
        // this.$swal({
        //   title: 'ปิดรับแทง',
        //   text: `ขออภัย ${data.LottoName} ปิดรับแทงแล้ว`,
        //   icon: 'warning',
        //   confirmButtonText: 'ตกลง',
        // })
      }
    },
    async ShowWallet() {
      this.ShowLog = true
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          '/seamless/wallet/show',
          { params },
        )
        this.ShowLog = false
        if (response && response.success === true) {
          const Main = response.WalletData
          this.userbalance = Main.balance
          this.discount = response.discount
        } else {
          this.userbalance = 0
        }
      } catch (err) {
        this.ShowLog = false
        console.log(err)
      }
    },
  },
}
</script>

<style scoped>
.box-lot {
  background: #fff;
  color: #000;
  border-radius: 10px;
  box-shadow: 0 1px 15px #0000001f;
}

.time-lot {
  text-align: center;
  padding: 2px 5px;
  background-color: #105fa6;
  border-radius: 10px 10px 0 0;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 500;
}

.rouded-lot {
  position: relative;
  padding: 10px 5px;
  line-height: 1.45;
}

.rouded-lot .p1 {
  font-size: 1.05rem;
  padding-bottom: 10px;
}

.rouded-lot .p2 {
  font-size: 0.7rem;
  color: #aaaaaa;
  padding: 0;
}
.ico-next {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.flex-fill {
  padding: 1px;
}

.view-rules {
  display: inline-flex;
  align-items: center;
  border: 0;
  text-align: center;
  background-color: #fcc201;
  border-radius: 13px;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 15px;
}

td {
  border: 1px solid #707070af;
  background: #fff;
  padding: 5px;
}

tr {
  margin: auto;
  width: 320px;
}

.form-css {
  border: none !important;
  border-radius: 10px !important;
}

.btns-bet-l {
  width: calc(100% - 110px);
}

.row-top-action {
  background-color: rgb(93, 173, 240) !important;
  /* background-image: url(/back_bg.svg) !important; */
}

.row-bottom-action {
  background-color: #0c2574 !important;
  /* background-image: url(/back_bg.svg) !important; */
}

@media only screen and (max-width: 768px) {
  .row-bottom-action {
    width: 100% !important;
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .bet-action-bottom .bet-detail {
    width: 50%;
    border-bottom: 1px solid #7f0000;
  }
}

.bet-action-bottom .bet-detail {
  min-width: 140px;
  padding: 10px 20px;
  border-right: 1px solid #7f0000;
}

.input-bottom-bet {
  width: calc(100% - 90px);
  height: 44px;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 700;
  color: #ff6700;
  border: none;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .bet-action-bottom .bet-button,
  .bet-action-bottom .bet-form {
    margin: 0 auto;
    width: 100%;
  }
}

.bet-button {
  padding: 5px 20px 15px;
  width: 360px;
  display: flex;
}

.float-left {
  float: left !important;
}

a:focus,
input {
  outline: 0;
}

.color-sub {
  color: #fff;
}

.color-white {
  color: #fff;
}

.font-page-title {
  font-size: 20px;
}

.bet-list-item.yellow {
  color: #edad0c;
}

.bet-list-item.orange {
  color: #ff6700;
}

.bet-list-item.green {
  color: #2ca468;
}

.bet-list-item.yellow.selected,
.bet-list-item.yellow.selected:hover {
  background-color: #fcc201;
  color: #fff;
}

.bet-list-item {
  float: left;
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  line-height: 20px;
  color: #818181;
  font-size: 12px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}

.font-page-title {
  font-size: 20px;
}

.bet-sm-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #818181;
  text-align: center;
  background-color: #fff;
  display: block;
}

.btn1.active {
  background: #3b5898 !important;
  color: #fff !important;
}

.button-num-cal {
  width: 100%;
  background: linear-gradient(180deg, #fff 0, #f1f1f1 100%);
  border: none;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-num-cals {
  width: 100%;
  background: linear-gradient(180deg, #424242 0, #202020 100%);
  border: none;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-num-calss {
  width: 100%;
  background: linear-gradient(180deg, #424242 0, #202020 100%);
  border: none;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-action-cal,
.button-num-cal {
  box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 4px;
  text-align: center;
  height: 45px;
}

.clicked {
  background: #3b5898 !important;
  color: #fff !important;
}

.btns,
.input-2-box .text-control {
  font-weight: 700;
  padding: 10px;
  line-height: 24px;
}

.bg-cradit {
  margin: auto;
  background-color: #fff;
  color: #000;
  width: 150px;
  font-size: 0.6rem;
  border-radius: 10px;
}

.condition-top {
  background-color: #d40000;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
  color: #fff;
}

.condition-body {
  background-color: #838383;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}

.btn-type3 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  /* border-radius: 25px; */
  font-weight: 700;
  border: 0px;
}

.btn-type2 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.btn-type1 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.active,
.btnp:focus {
  border-bottom: 4px solid #0c2574;
  color: #0c2574;
}

.header-bg-content2 {
  background-color: #fbc02d;
  border-bottom: 2px solid #0c2574;
}

.bg-side {
  background-color: #111;
  border-radius: 5px;
  padding: 2px;
  color: #fff;
}

.pdd {
  margin: auto;
  max-width: 540px;
}

hr {
  margin-top: -2px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(221, 221, 221, 0.75),
    rgba(0, 0, 0, 0)
  );
}

p {
  line-height: 0.7;
}

.bg-danger {
  background-color: #dc3545 !important;
}

/* .text-white {
  color: rgb(0, 115, 160) !important;
} */

@media screen and (max-width: 768px) {
  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-tanghuay {
  line-height: 1;
}

.action-bar.bar-white .nav-left {
  color: rgb(0, 0, 0) !important;
  font-weight: 700;
}

.action-bar.bar-white .nav-right {
  color: rgb(0, 0, 0) !important;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import "@core/scss/vue/pages/page-auth.scss";

.form-control {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0rem;
}

.style-1 {
  .otp-input-8-field input {
    border-radius: 5;
    border: 2px solid #c50000;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}

h4 {
  font-size: 16px !important;
}

.loader  {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}
</style>
